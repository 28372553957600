import consumer from "../../frontend/channels/consumer"

consumer.subscriptions.create("NotificationChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    if ($('#notification-bell').hasClass('d-none')) {
      $('#notification-bell').removeClass('d-none')
    }
    $('#notification-counter').text((parseInt($('#notification-counter').text()) + 1));
    $("#notification").prepend(data);
  }
});
