// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import '../js/bootstrap_js_files.js'
import "jquery"


Rails.start()
Turbolinks.start()
ActiveStorage.start()
const URL_API_PATH = process.env.API + 'api/';

document.addEventListener("turbolinks:load", () => {
  // Code here will be executed once the page is loaded but before it is rendered

  // Manage requirement's urgent button
  $('span.urgent.dot').on('click', function () {
    let urgentBtn = $(this);

    if (urgentBtn.hasClass('red')) {
      urgentBtn.removeClass('red');
    }
    else {
      urgentBtn.addClass('red');
    }
  });

  // Change requirement's period
  $('a.change-period').on('click', function (e) {
    let wrapper = $(this).parents('tr').get(0);
    let requirementId = wrapper.id;
    let url = '/requirements/' + requirementId;
    let commit = $(this).text();
    let periodFrom = $(this).attr('period');
    let periodTo;

    if (periodFrom == 1) {
      periodTo = 2;
    } else if (periodFrom == 2) {
      periodTo = 1;
    };

    let data = {
      'requirement': {
        'period_id': periodTo
      },
      'commit': commit
    };

    e.preventDefault();

    $.ajax({
      method: 'PATCH',
      url: url,
      dataType: 'script',
      data: data
    })
      .done(function (data, textStatus, jqXHR) {
        if (jqXHR.status != 200) {
          alert(textStatus, jqXHR.status);
        } else {
          let requirementTr = $('tr#' + requirementId)
          let cityContainer = requirementTr.parents('div.requirements-box')

          cityContainer.find('tbody').children().length == 1 ? cityContainer.remove() : requirementTr.remove();
        }
        data
      })
      .fail(function (jqXHR, textStatus, errorThrown) {
        alert(textStatus, errorThrown);
      })
  })

  // Save operation's information for save, repair, resume and discard.
  $('td.save a, a.commit').on('click', function (e) {
    let pathname = window.location.pathname
    let wrapper = $(this).parents('tr').get(0);
    let requirementId = wrapper.id;
    let url = '/requirements/' + requirementId;
    let urgentBtn = $(wrapper).find('.urgent.dot');
    let facultyApprovedBtn = $(wrapper).find('input#faculties_approved');
    let companyApprovedBtn = $(wrapper).find('input#company_approved');
    let returnedBtn = $(wrapper).find('span.returned.dot')
    let commit = $(this).text();
    let urgent = urgentBtn.hasClass('red') ? true : false;
    let period = $(wrapper).find('input#requirement_period_id').val();
    let facultyApproved = facultyApprovedBtn.is(':checked') ? true : false;
    let companyApproved = companyApprovedBtn.is(':checked') ? true : false;
    let returned = returnedBtn.hasClass('green') ? true : false;
    let observations = $(wrapper).find('textarea#observations').val();
    let status;

    if ((pathname == '/requirements' || pathname == '/') && commit == 'Guardar') {
      status = $(wrapper).children('#requirement_status').val();
    } else if (commit == 'Retomar') {
      status = 'Pendiente';
      facultyApproved = false;
      companyApproved = false;
      returned = true;
    } else if (commit == 'Disolver') {
      status = 'Disuelto';
    } else if (commit == 'Duplicado') {
      status = 'Duplicado'
    }

    let data = facultyApprovedBtn.length > 0 ? {
      'requirement': {
        'urgent': urgent,
        'period_id': period,
        'faculties_approved': facultyApproved,
        'company_approved': companyApproved,
        'status': status,
        'observations': observations,
        'returned': returned
      },
      'commit': commit
    } : {
      'requirement': {
        'urgent': urgent,
        'period_id': period,
        'company_approved': companyApproved,
        'status': status,
        'observations': observations,
        'returned': returned
      },
      'commit': commit
    }

    e.preventDefault();

    $.ajax({
      method: 'PATCH',
      url: url,
      dataType: 'script',
      data: data,
      done: function (data, textStatus, jqXHR) {
        if (jqXHR.status != 200) {
          alert(textStatus, jqXHR.status);
        }
        data
      },
      fail: function (jqXHR, textStatus, errorThrown) {
        alert(textStatus, errorThrown);
      }
    })
  });

  // Manage massive assignment collapsible button
  $('a#massive-assignment-collapsible-btn').on('click', function () {
    let massiveBtn = $(this)

    if (massiveBtn.attr('aria-expanded') === 'true') {
      massiveBtn.html('')
      massiveBtn.removeClass('btn-outline-secondary').addClass('btn-close mx-auto')
    } else if (massiveBtn.attr('aria-expanded') === 'false') {
      massiveBtn.html('Asignación masiva')
      massiveBtn.removeClass('btn-close mx-auto').addClass('btn-outline-secondary')
    }
  })
});

function Notify(text, callback, close_callback, style, modal) {

  var time = '10000';
  let $container = $('#notifications');
  if (modal == true)
    $container = $('#notifications_modal');
  var icon = '<i class="fa fa-info-circle "></i>';

  if (typeof style == 'undefined') style = 'warning'

  var html = $('<div class="alert alert-' + style + '  hide">' + icon + " " + text + '</div>');

  $('<a>', {
    text: '×',
    class: 'button close',
    style: 'padding-left: 10px;',
    href: '#',
    click: function (e) {
      e.preventDefault()
      close_callback && close_callback()
      remove_notice()
    }
  }).prependTo(html)

  $container.prepend(html)
  html.removeClass('hide').hide().fadeIn('slow')

  function remove_notice() {
    html.stop().fadeOut('slow').remove()
  }

  var timer = setInterval(remove_notice, time);

  $(html).hover(function () {
    clearInterval(timer);
  }, function () {
    timer = setInterval(remove_notice, time);
  });

  html.on('click', function () {
    clearInterval(timer)
    callback && callback()
    remove_notice()
  });


}

function refreshWindow() {
  setTimeout(function () {
    window.location.reload();
  }, 1500);
}

// Update faculty's information
function saveModel(model, id, path) {

  let wrapper = $('tr#' + id).get(0);
  let url = path + id;
  let status = $(wrapper).find('select#status option:selected').text();
  let assigned = $(wrapper).find('select#user option:selected').val();
  let my_id = $(wrapper).find('td.user_id').attr("user_id");
  let assigned_by_user_id = $(wrapper).find('td.user_id').attr("assigned_by_user_id");
  let data = null;
  let url_path = null;
  let data_type = null;
  let is_modal = false;
  let method_type = null;



  // Este caso solo aplica para "Nuevos Poderes"
  if (model == 'assignaments') {
    let comment = $(wrapper).find('textarea#comment').val();
    console.log('comment: ' + comment)
    method_type = 'PUT';
    url_path = URL_API_PATH + "faculty_ok/" + id;
    data_type = 'json';
    is_modal = false;
    data = {
      'my_id': my_id,
      'assigned_by_user_id': assigned_by_user_id,
      'comment': comment,
      'status': status,
      'update_user_assignament': true
    }
  }
  else if (model == 'faculty') { // En caso de guardar un poder: Perfil de abogado
    url_path = URL_API_PATH + url;
    data = {
      'status': status,
      'user_id': assigned == "" ? null : assigned,
      'my_id': my_id
    };
    data_type = 'json';
    is_modal = true;
    method_type = 'PATCH';
  }

  if (status != 'Seleccione un estado') {
    $.ajax({
      method: method_type,
      url: url_path,
      dataType: data_type,
      data: data,
      success: function (data, textStatus, jqXHR) {
        try {
          if (data.code == 200) {

            Notify("Guardado correctamente", null, null, 'success', is_modal);
            if (model == 'assignaments')
              refreshWindow();

          }
          else {
            Notify("Ha ocurrido un error interno, favor contacte con el administrador.", null, null, 'danger', is_modal);
          }
        }
        catch {
          Notify("Ha ocurrido un error interno, favor contacte con el administrador.", null, null, 'danger', is_modal);
        }
      },
      fail: function (jqXHR, textStatus, errorThrown) {
        Notify("Ha ocurrido un error interno, favor contacte con el administrador.", null, null, 'danger', is_modal);
      }
    })
  } else {
    Notify("Por favor seleccione un estado.", null, null, 'warning', is_modal);
  }
};

function onChangeStatusFaculty(id) {

  const wrapper = $('tr#' + id).get(0);
  const status = $(wrapper).find('select#status option:selected').text();
  const enableValues = ['Notaria', 'Archivo Judicial'];
  if (enableValues.indexOf(status) >= 0) {
    $(wrapper).find('select#user').removeAttr('disabled');
  }
  else {
    $(wrapper).find('select#user').attr('disabled', 'disabled');
  }
};

function checkAssginedDrop() {
  $(".faculties tr").each(function () {
    onChangeStatusFaculty(this.id)
  });
}

function modalScss() {
  var commentContainerCss = `
    .comment-container {
      background-color: rgb(230, 240, 210);
      border: 1px solid rgb(197, 207, 178);
      border-radius: 10px;
      width: 30rem;
    }`;
  var commentsScrollCss = `
    .comments-scroll {
      max-height: 35rem;
      overflow-y: scroll;
      overflow-x: hidden;
    }`;

  var commentDataCss = `
  .comment-data {
    font-size: small;
    color: #494e5a;
  }`;

  var head = document.head || document.getElementsByTagName('head')[0];
  var style = document.createElement('style');

  head.appendChild(style);
  style.type = 'text/css';
  style.appendChild(document.createTextNode(commentContainerCss))
  style.appendChild(document.createTextNode(commentsScrollCss))
  style.appendChild(document.createTextNode(commentDataCss))
}

window.refreshWindow = refreshWindow;
window.Notify = Notify;
window.checkAssginedDrop = checkAssginedDrop;
window.saveModel = saveModel;
window.onChangeStatusFaculty = onChangeStatusFaculty;
window.modalScss = modalScss;


